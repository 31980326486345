@keyframes vkui-rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes vkui-popper-fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}
